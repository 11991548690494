.designerTable__heading {
    display: inline-block;
    padding: 1rem 0 0.5rem 1rem;
    text-align: center;
}
.designerTableHeadings__container {
    border-top: var(--color-blue-vavakada) 2px solid;
    border-bottom: var(--color-blue-vavakada) 2px solid;
    color: var(--color-text);
    background-color: var(--color-blue-vavakada);
    font-size: 1.6rem;
}

.designerTable_startPainting {
    width: 10%;
}

.designerTable_num {
    width: 6%;
}

.designerTable_store {
    width: 9%;
}

.designerTable_size {
    width: 5%;
}

.designerTable_product {
    width: 10%;
}

.designerTable_count {
    width: 5%;
}

.designerTable_deadline {
    width: 10%;
}

.designerTable_status {
    width: 10%;
}

.designerTable_sentToPrint {
    width: 10%;
}

.designerTable_notes {
    width: 15%;
}

.designerTable_working {
    width: 10%;
}