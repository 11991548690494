.artistTable__heading {
    display: inline-block;
    padding: 1rem 0 0.5rem 1rem;
    text-align: center;
}
.artistTableHeadings__container {
    border-top: var(--color-blue-vavakada) 2px solid;
    border-bottom: var(--color-blue-vavakada) 2px solid;
    color: var(--color-text);
    background-color: var(--color-blue-vavakada);
    font-size: 1.6rem;
}

.artistTable_startPainting {
    width: 10%;
}
.artistTable_date {
    width: 10%
}

.artistTable_num {
    width: 10%;
}

.artistTable_date {
    width: 10%;
}

.artistTable_artistStatus {
    width: 10%;
}

.artistTable_size {
    width: 10%;
}

.artistTable_product {
    width: 10%;
}

.artistTable_count {
    width: 5%;
}

.artistTable_price {
    width: 5%;
}

.artistTable_deadline {
    width: 10%;
}

.artistTable_designer {
    width: 10%;
}

.artistTable_notes {
    width: 20%;
}

.artistTable_priority {
    width: 5%;
}