.dropdown__col {
    display: inline-block;
    width: 20%;
    padding: 2rem 1rem 2rem 1rem;
    vertical-align: top;
}

.artist_notes {
    width: 20%;
}

.dropdown__heading {
    color: var(--color-blue-vavakada);
    font-size: 1.6rem;
}

.note_heading {
    color: var(--color-red-pure)
}

.dropdown__address {
    width: 20%;
}

.image_upload {
    display: inline-block;
}

.dropdown__tracking {
    color: var(--color-aqua-500);
}

.dropdown__clickable__payment {
    transform: scale(1.5);
}

.order__artist__prize {
    width: 25%;
    margin: 2rem 1rem 2rem 1rem;
    text-align: center;
    background-color: var(--color-aqua-200);
    font-weight: inherit;
}

.dropdown__tracking {
    width: 80%;
}


.add_tracking_button {
    color: var(--color-blue-vavakada);
    transition: all .2s ease-out;
    cursor: pointer;
}

.add_tracking_button:hover {
    color: var(--color-red-900);
    transform: scale(1.08);
}

.tracking_value {
    color: var(--color-text);
    word-break: break-all;
}

.dropdown__artist__prize, .dropdown_payArtist {
    display: inline-block;
    width: 50%;
}

.image_upload__btn {
    width: 40%;
    visibility: hidden;
}

.image_upload label.submit {
    display: inline-block;
    width: 100%;
    background-color: var(--color-secondary);
    padding: .2rem 0 .2rem 0;
    text-align: center;
    margin: auto
}

.image_upload label.submit:hover {
    background-color: var(--color-blue-vavakada);
    color: var(--color-text);
    transition: all .2s;
    transform: scale(1.15);
}

.dropdown__image__item {
    display: inline-block;
    padding: 0 .1rem 0 .1rem;
    text-align: center;
}

.upload__container {
    text-align: center;
    width: 50%;
    margin: auto;
    height: 1rem !important;

}

.upload__container__heading {
    text-align: center;
}

.dropdown__images__container {
    text-align: center;
}

.dropdown__refund__heading {
    color: var(--color-secondary);
    padding-right: 1rem;
}

.dropdown_username {
    color: var(--color-secondary);
}

.dropdown_delete_note {
    cursor: pointer;
    color: var(--color-red-signal);
}

.dropdown__copy_icon {
    margin-left: 2rem;
    color: var(--color-secondary);
    cursor: pointer;
}

.dropdown__copy_icon_small {
    margin-left: 1rem;
    color: var(--color-secondary);
    cursor: pointer;
}
