.artist__date_picker {
    z-index: 10;
    background-color: var(--color-aqua-200);
    width: 50%;
    text-align: center;
    border-radius: 1rem;
}

.artistInfo_heading {
    text-align: center;
    font-size: 3rem;
    margin: 2rem;
}

.artist_info_date_container {
    display: inline-block;
    width: 45%;
    text-align: center;
    font-size: 1.5rem;
}

.react-datepicker__month-container {
    background-color: var(--color-aqua-200);
    transform: scale(1.1);
}

.react-datepicker-popper {
   offset: path("M 200 100 L 300 100 L 200 300 z") auto;
}

.artistInfo_artist_col {
    width: 25%;
    text-align: center;
    display: inline-block;
    font-size: 2rem;
}

.artistInfo_blue {
    color: var(--color-blue-vavakada);
}

.artistInfo_green {
    color: var(--color-secondary);
}

.artist_info_artists_container, .artist_info_frames_container {
    margin-top: 2rem;
}