.download_all_container {
    margin: 2rem;
}

.unmarkAll_btn {
    background-color: var(--color-blue-vavakada) !important;
}

.toPrint {
    color: black;
    display: block !important;
    font-size: 1.1rem;
    font-weight: normal;
}

.image__to__print {
    width: 180px;
    height: auto;
}

.text__to__print__container, .image__to__print__container {
    display: inline-block !important;
    width: 40% !important;
    vertical-align: top !important;
}


@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always;
    }
}

@page {
    size: auto;
    margin: 20mm;
}