.createOrder_mainContainer {
    margin: 12rem 2rem 2rem 2rem;
}

.createOrder_heading {
    text-align: center;
    font-size: 2rem;
    margin: 2rem;
}

.createOrder_row {
    display: inline-block;
    margin: 2rem;
    width: 22%;
    transition: all .2s;
}

.createOrder_row:hover {
    transform: scale(1.2);
}

.createOrder_store, .customerEmail_input, .shippingPhone_input, .shippingAddress_input, .customerName_input, .createOrder_frame,
.shippingCountry_input, .shippingZip_input, .shippingState_input, .shippingCity_input, .size_input, .product_input {
    width: 90%;
    border: 2px solid var(--color-secondary);
    background-color: var(--color-aqua-200);
    border-radius: 1.5rem;
    height: 4rem;
    font-size: 1.5rem;
}

.input_heading {
    text-align: center;
}

.createOrder_frame, .createOrder_store {
    cursor: pointer;
    text-align: center;
}

.createOrder_manual_number {
    color: var(--color-secondary);
}

.createOrder_submit {
    width: 30% !important;
    margin: auto !important;
}