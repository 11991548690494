.adminContainer {
    padding: 12rem 1rem 1rem 1rem;
}

.newUserHeading, .editUsersHeading {
    font-weight: bold;
    text-align: center;
    padding-bottom: 1.6rem;
}

.createUser_container {
    width: 50%;
    margin: auto;
}

.createUserMail {
    width: 50%
}

.createUser_options {
    height: 4rem;
    border-radius: .7rem;
    width: 50%;
    text-align: center;
    cursor: pointer;
    transition: all .2s ease-out;
    background-color: var(--color-aqua-200);
}

@media only screen and (min-width: 993px) {

    .createUser_options:hover {
        transform: translateY(-3px) translateX(-4px) scaleY(1.1);
        box-shadow: 0 2rem 4rem rgba(0, 0, 0, .5);
    }
}

.createUserBtn {
    background-color: var(--color-secondary);
    width: 100%;
}

.createUser_nickname {
    background-color: var(--color-aqua-200);
    width: 50%;
}

.adminContainer {
    margin: 2rem;
}

.artists_container, .printCenter_container, .designers_container {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}

.print_center_heading {
    margin-top: 4rem;
}

.createOrder_container {
    text-align: center;
    margin: 3rem;
    font-size: 2rem;
}

.admin_pageLink {
    margin: 2rem;
}
