.artistTable_date {
    width: 10%
}

.artistTable_num {
    width: 10%;
}

.artistTable_size {
    width: 10%;
}

.artistTable_product {
    width: 10%;
}

.artistTable_count {
    width: 5%;
}

.artistTable_deadline {
    width: 10%;
}

.artistTable_status {
    width: 10%;
}

.artistTable_notes {
    width: 12%;
}

.artistTable_money {
    width: 5%;
}

