.orderRow {
    display: inline-block;
    padding: 1rem;
    text-align: center;
    vertical-align: top;
    text-decoration: inherit;
}

.order__container {
    border-top: var(--color-blue-vavakada) 2px solid;
}

.order__container__main:hover {
    background-color: var(--color-secondary) !important;
    transform: scaleY(1.2);
    transition: all .2s;
}

.orderRowRed {
    background-color: var(--color-red-pure) !important;
}

.orderRowLightBlue {
    background-color: var(--color-blue-700);
}

.dropdown__clickable__priority, .dropdown__clickable__emergency, .dropdown__clickable {
    cursor: pointer;
}


.dropdown__clickable__emergency__span {
    padding: 0.7rem;
    background-color: var(--color-red-pure);
    border-radius: .75rem;
}

.dropdown__clickable__prior__span {
    padding: 0.7rem;
    background-color: var(--color-blue-700);
    border-radius: .75rem;
}


.status_options {
    width: 90%;
    height: 2.5rem;
    border-radius: .7rem;
    text-align: center;
    cursor: pointer;
    background-color: var(--color-blue-vavakada);
    color: var(--color-text);
    font-weight: inherit;
    border: 2px solid var(--color-blue-300);
}

.artist_status_options {
    width: 70%;
}

.orderRowNoBorder {
    border-top-color: var(--color-background-dark);
}

.artist__paid {
    background-color: var(--color-hover) !important;
}

.order__notes {
    width: 90%;
    height: 2.8rem;
    word-break: break-all;
    background-color: var(--color-aqua-200);
    border-radius: 0.5rem;
}

.tracking__input {
    width: 90%;
    word-break: break-all;
    background-color: var(--color-aqua-200);
}

.print_center_col_notes {
    width: 15%!important;
}

.note_value {
    word-break: break-all;
}

.order_has_note {
    background-color: var(--color-red-pure);
}

.designer_option {
    background-color: var(--color-purple-500);
}

.painting_option {
    background-color: var(--color-pink-500);
}

.printCenter_option {
    background-color: var(--color-yellow-700);
}

.ready_option {
    background-color: var(--color-red-500);
}

.finished_option {
    background-color: var(--color-green-700);
}

.fulfilled_option {
    background-color: var(--color-gray-700);
}

.atoBeSpecified_option {
    background-color: var(--color-yellow-300);
    color: var(--color-background-dark);
}

.order_refunded {
    text-decoration-line: line-through;
    text-decoration-color: var(--color-red-signal);
    text-decoration-thickness: 0.5rem;
}

.deadline_red_color {
    background-color: var(--color-red-signal);
}

.dropdown_order_tracking_appended {
    background-color: var(--color-secondary);
}

.heroBackground {
    background-color: var(--color-dog-hero);
    font-weight: inherit;
}

.landBackground {
    background-color: var(--color-dog-land);
    font-weight: inherit;
}
.poopyBackground{
    background-color: var(--color-custom-pet);
    font-weight: inherit;
}

.payment__input {
    background-color: var(--color-aqua-900);
    border-radius: 1rem;
    width: 2rem;
    height: auto;
    padding: .6rem;
}

.payment__checkbox {
    cursor: pointer;
}
