.user_row_container {
    margin: 1rem 0 1rem 0;
}

.admin__user__nickname {
    display: inline-block;
    font-size: 2rem;
    width: 35%;
}

.artist__painting_count {
    margin-left: 1rem;
    display: inline-block;
    font-size: 2rem;
    width: 5%;
}

.edit_btn, .confirm_btn, .delete_btn, .suspend_btn, .start_btn {
    display: inline-block;
    background-color: var(--color-secondary);
    color: var(--color-background-dark);
    font-weight: inherit;
    margin: 0 1rem 0 1rem;
}

.edit_btn {
    background-color: var(--color-secondary);
}

.delete_btn {
    background-color: var(--color-red-900);
}

.confirm_btn {
    background-color: var(--color-blue-vavakada);
}

.edit_nickname_input {
    display: inline-block;
    background-color: var(--color-aqua-200);
    width: 50%;
}

.modal_btns {
    text-align: center;
}

.delete_user_nickname {
    font-size: 2rem;
    color: var(--color-blue-vavakada);
}

.start_btn {
    background-color: var(--color-blue-vavakada);
}

.suspend_btn {
    background-color: var(--color-red-500);
}

.user_note {
    display: inline-block;
    width: 15%;
}
