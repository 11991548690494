.dropdown__image__img {
    max-width: 7rem;
    max-height: 7rem;
    cursor: pointer;
}

.dropdown__image__img:hover {
    transform: scale(2);
    transition: .2s ease-out;
}

.large__image__container {
    max-height: 72vh;
    max-width: 80vw;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.loading__image__container {
    max-height: 11vh;
    max-width: 30vw;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal__container {
    transition: 1s ease-out 1s;
}

.close__modal__btn, .download__modal__btn, .newTab__modal__btn, .previous__image__btn, .next__image__btn, .downloadPdf__btn {
    background-color: var(--color-red-signal);
    padding: .5rem 1rem .5rem 1rem;
    border-radius: 1rem;
    float: right;

    margin: -1rem 1rem 1rem 1rem;
    font-weight: inherit;
}

.close__modal__btn:hover, .download__modal__btn:hover, .newTab__modal__btn:hover, .downloadPdf__btn:hover {
    background-color: var(--color-red-700);
    color: var(--color-text);
    transform: scale(1.15);
    transition: .2s ease-out;
}

.download__modal__btn {
    float: initial;
    background-color: var(--color-blue-vavakada);
}

.newTab__modal__btn {
    float: initial;
    background-color: var(--color-secondary);
}

.downloadPdf__btn {
    float: initial;
    background-color: var(--color-red-pure);
}

.previous__btn__container, .next__btn__container, .image__container {
    display: inline-block;
}

.image__item__arrow__left {
    color: var(--color-secondary);
    margin-right: 1rem;
    cursor: pointer;
}

.image__item__arrow__right {
    color: var(--color-secondary);
    margin-left: 1rem;
    cursor: pointer;
}

@keyframes openModal {
    0% {
        opacity: 1;
        transform: scale(1.2);
    }

    50% {
        opacity: .5;
        transform: scale(1.4);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}