.progress_history_button {
    color: var(--color-secondary);
}

.progress_history_modal {
    color: var(--color-background-dark);
}

.progress_history_close_btn {
    float: none;
}