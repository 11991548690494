
.payment__statistics_container {
    font-size: 2rem;
}

.pay_all_button {
    font-weight: inherit;
    background-color: var(--color-red-signal);
    font-size: 1.6rem;
}

.pay_all_text {
    text-align: center;
}