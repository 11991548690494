.home__container {
    padding: 5.9rem 1rem 1rem 1rem;
}

.login_container {
    padding: 15rem 1rem 1rem 1rem;
}

.print_center_btn {
    display: inline-block;
}

@media only screen and (max-width: 992px) {
    .inline__input, .mail__input {
        display: inline-block !important;
        width: 100% !important;
    }

    .button__form {
        display: inline-block !important;
        width: 100% !important;
    }

    .services__col {
        padding: 2vh 5vw 2vh 5vw !important;
    }

    .service__text__col {
        padding-left: 5vw !important;
    }

    .gallery__container {
        min-height: 50rem !important;
    }
}

@media only screen and (max-width: 77rem) {
    .gallery__container {
        min-height: 30rem !important;
    }
}

::placeholder, .copyright {
    text-align: center;
}

.copyright {
    margin: 2rem;
    color: var(--color-blue-vavakada);

    /*position: fixed;*/
    /*bottom: 35px;*/
    /*left: 50vw;*/
    /*transform: translate(-100px, 0);*/
}

.mail__input {
    height: 4rem;
    border-radius: .7rem;
}

.pass__row {
    white-space: nowrap;
    overflow-x: auto;
}


.inline__input {
    height: 4rem;
    display: inline-block;
    width: 50%;
    border-radius: .7rem;
}

.button__form {
    font-weight: bold;
    height: 4rem;
    display: inline-block;
    width: 50%;
    padding-left: .5rem;
    border-radius: .7rem;
}

@media only screen and (min-width: 993px) {

    .button__form:hover {
        transform: translateY(-3px) translateX(-4px) scaleY(1.1);
        box-shadow: 0 2rem 4rem rgba(0, 0, 0, .5);
    }

    .button__form:active {
        transform: translate(-1px);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
    }
}

h3 {
    padding: 2vh 0 2vh 0;
    text-align: center !important;
    font-size: 2.4rem;
}

.services__col {
    padding: 2vh 0 2vh 0;
}

.service__icon {
    margin-left: 1.5vw;
    color: #359df7;
    transition: all .2s;
}

.skew:hover .service__icon {
    filter: drop-shadow(0.5rem 1rem 2rem rgba(13, 110, 253, .5));
}

.service__text__col {
    padding-left: 1vw;
}

.slick__image {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all .2s;
}

.slick__image:hover {
    transform: scale(1.2);
    text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

.gallery__container {
    min-height: 70rem;
    position: relative;
}

h3, h5, .logged__in__heading {
    padding: 2vh 0 2vh 0;
    text-align: center !important;
    color: var(--color-text);
    font-weight: bold;
}

.container {
    animation: moveInLeft 1s ease-out;
}

h3 {
    font-size: 2.4rem;
}

h5 {
    font-size: 2rem;
    transition: all .2s;
}

.skew {
    transition: all .2s;
}

.skew:hover {
    transform: skewY(-3deg) skewX(-15deg) scale(1.25);
    text-shadow: 0.5rem 1rem 2rem rgba(13, 110, 253, .5);
}

.skew:hover h5 {
    text-shadow: 0.5rem 1rem 2rem rgba(255, 255, 255, .3);
}

.skew:hover .skew__not {
    transform: skewY(3deg) skewX(15deg);
}

.welcome__heading {
    animation: moveInUp .5s ease-out .5s;
    animation-fill-mode: backwards;
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInUp {
    0% {
        opacity: 0;
        transform: translateY(-20rem);
    }

    80% {
        transform: translateY(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}
