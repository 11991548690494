.icon {
  height: 45px;
}

.header__logo {
  height: 60px;
}

.footer__logo {
  height: 40px;
}

.hot__takes__viewer__logo{
  width: 70px;
}

.header__button__icon {
  background: #26232b;
  width: 65px;
  height: 65px;
  border-radius: 999px;
  padding: 10px;
}

.project__icon {
  border-radius: 999px;
  height: 45px;
  width: 45px;
  margin: 5px;
  cursor: pointer;
}

.project__icon:hover {
  transform: scale(1.2);
}

.button__icon {
  height: 30px;
  width: 30px;
  padding: 2px;
}

.hot__takes__card__image{
  height: 206px;
  width: 165px;
  border-radius: 8px;
}

.hot__takes__image{
  height: 360px;
  width: 300px;
  border-radius: 8px;
}

.hot__takes__card__icons{
  height: 25px;
  width: 25px;
}

.hot__takes__miniature__image{
  height: 80px;
  width: 70px;
  border-radius: 8px;
}

.hot__takes__title__image{
  width: 300px;
  height: 50px;
}

/* Responsive */
