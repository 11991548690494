.orderTable__heading {
    display: inline-block;
    padding: 1rem 0 0.5rem 1rem;
    text-align: center;
}
.orderTableHeadings__container {
    border-top: var(--color-blue-vavakada) 2px solid;
    border-bottom: var(--color-blue-vavakada) 2px solid;
    color: var(--color-text);
    background-color: var(--color-blue-vavakada);
    font-size: 1.6rem;
}

.print_center_notes_heading {
    width: 20%!important;
}