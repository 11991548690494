.orderTable_num {
    width: 6%;
}

.orderTable_date {
    width: 6%;
}

.orderTable_customer {
    width: 11%;
}

.orderTable_size {
    width: 5%;
}

.orderTable_product {
    width: 10%;
}

.orderTable_count {
    width: 3%;
}

.orderTable_price {
    width: 5%;
}

.orderTable_deadline {
    width: 6%;
}

.orderTable_sentToPrint {
    width: 8%;
}

.orderTable_status {
    width: 10%;
}

.printTable_working {
    width: 5%;
}

.orderTable_artist {
    width: 10%;
}

.orderTable_designer {
    width: 10%;
}

.orderTable_notes {
    width: 12%;
}

.orderTable_priority {
    width: 5%;
}

.orderTable_working {
    width: 10%;
}

.access__denied {
    font-size: 3rem;
    text-align: center;
    margin-top: 10rem;
}