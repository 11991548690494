.navContainer {
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--color-background-light);
    z-index: 100;
}

.navLink {
    display: inline-block;
    font-size: 2rem;
    width: 12%;
}

.monthlySum {
    display: inline-block;
    font-size: 1.5rem;
    width: 5%;
}

.admin__link:hover, .fulfilled__link:hover, .active__link:hover {
    transition: all .2s ease-out;
    transform: scale(1.2)
}

.filters__container {
    font-size: 1.6rem;
}

.heading__filter {
    width: 50%;
    margin-left: 1rem;
}

.logoutUser__container {
    float: right;
    padding-right: 4rem;
    margin-top: 1rem;
}

.user__nickname {
    font-size: 1.6rem;
}

.fulfilled_logo {
    width: 9rem;
    height: auto;
}

.admin_logo {
    width: 7rem;
    height: auto;
}

.payment_logo {
    width: 5rem;
    height: auto;
}

.fulfilled__link, .admin__link {
    width: 6%;
}

.heading_ordersCount {
    color: var(--color-secondary);
}

.heading__active {
    color: var(--color-blue-vavakada);
}

@media only screen and (min-width: 600px) {
    .paw_link {
        display: none;
    }
}
