.order__date_picker {
    z-index: 10;
    background-color: var(--color-aqua-200);
    width: 120%;
}

.cursor_none {
    cursor: auto !important;
}

.edit_price_input {
    width: 100%;
    background-color: var(--color-aqua-200);
    text-align: center;
}