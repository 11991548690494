.designerTable_date {
    width: 10%
}

.designerTable_num {
    width: 10%;
}

.designerTable_size {
    width: 10%;
}

.designerTable_product {
    width: 10%;
}

.designerTable_count {
    width: 5%;
}

.designerTable_deadline {
    width: 10%;
}

.designerTable_status {
    width: 10% !important;
}

.designerTable_notes {
    width: 15% !important;
}

.designerTable_sentToPrint {
    width: 10%;
}

.designerTable_working {
    width: 10%;
}

.gray_status {
    background-color: var(--color-gray-300);
}

.dropdown__clickable__working__span {
    padding: 0.7rem;
    background-color: var(--color-aqua-900);
    border-radius: .75rem;
}

.designerWorking_background {
    background-color: var(--color-aqua-900);
}
